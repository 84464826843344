<template>
	<div>
		<div class="tabs-wrapper">
			<q-tabs
				indicator-color="primary"
				align="justify"
				active-color="primary"
				v-if="ready"
			>
				<q-route-tab
					v-for="(tab, index) in tabs"
					:key="index"
					:to="{ name: tab.path_name }"
					:label="tab.label"
				/>
			</q-tabs>
		</div>
		<div class="router-view-wrapper">
			<div>
				<router-view v-if="ready"></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	name: 'Base',
	data() {
		return {
			ready: false,
			tabs: [],
			tabs_data: [
				{
					path_name: 'Dataset Devices',
					label: 'Devices',
				},
				{
					path_name: 'Dataset Data',
					label: 'Data',
				},
				{
					path_name: 'Dataset Settings',
					label: 'Settings',
				},
				{
					path_name: 'Dataset Audit',
					label: 'Audit',
				},
				{
					path_name: 'Dataset Sftp',
					label: 'SFTP',
				},
				{
					path_name: 'Dataset Conference',
					label: 'Conference',
				},
				{
					path_name: 'Dataset On Call',
					label: 'On Call',
				},
				{
					path_name: 'Dataset Registration',
					label: 'Registration',
				},
				{
					path_name: 'Dataset Merge Fields',
					label: 'Merge Fields',
				},
			],
		}
	},
	computed: {
		...mapGetters(['logged_user', 'customer_group']),
	},
	methods: {
		...mapActions([
			'getCustomerGroupSettings',
			'getAuditSettings',
			'getCustomerGroups',
			'getAuditRecipientsOptions',
			'getOnCallSettings',
			'getOnCallCgFields',
			'retrieveOnCallStaffing',
			'getCgFields',
			'getMergeFields',
			'getDataFields',
			'getContactFields',
		]),
		setTabs() {
			let permission = this.$store.getters.logged_user.customer_permission
			let is_root = this.$store.getters.is_root_cg
			let root_cg_permissions =
				this.$store.getters.current_CG.root_customer_group_permissions
			let operator_or_admin =
				permission === 'ADMIN' || permission === 'OPERATOR'
			let currently_viewed_group = parseInt(this.$route.params.id)
			let rcg_id = this.$store.getters.current_CG.customer_group.root_parent_id
			this.tabs = this.tabs_data.filter((tab) => {
				let include = null
				switch (tab.label) {
					case 'Devices':
						include = permission === 'ADMIN'
						break
					case 'Data':
						include = permission === 'ADMIN'
						break
					case 'Settings':
						include = permission === 'ADMIN'
						break
					case 'Audit':
						include = permission === 'ADMIN'
						break
					case 'SFTP':
						include =
							is_root &&
							permission === 'ADMIN' &&
							root_cg_permissions.includes('SFTP imports') &&
							currently_viewed_group === rcg_id
						break
					case 'Conference':
						include =
							permission === 'ADMIN' &&
							root_cg_permissions.includes('Conference bridge')
						break
					case 'On Call':
						include =
							permission === 'ADMIN' &&
							root_cg_permissions.includes('On Call core platform access')
						break
					case 'Registration':
						include =
							is_root &&
							operator_or_admin &&
							root_cg_permissions.includes('Registration page') &&
							this.$store.getters.current_CG.customer_group.has_registration_template &&
							currently_viewed_group === rcg_id
						break
					case 'Merge Fields':
						include =
							operator_or_admin && root_cg_permissions.includes('Merge fields')
						break
				}
				return include
			})
		},
	},
	async mounted() {
		this.$q.loading.show({
			delay: 400,
		})
		await this.getCustomerGroupSettings(this.$route.params.id)
		await this.getAuditSettings(this.$route.params.id)
		await this.getCustomerGroups()
		await this.getAuditRecipientsOptions(this.$route.params.id)
		await this.getOnCallSettings(this.$route.params.id)
		await this.retrieveOnCallStaffing(this.$route.params.id)
		await this.getCgFields(this.$route.params.id)
		await this.getMergeFields(this.$route.params.id)
		this.getOnCallCgFields()
		this.getDataFields(parseInt(this.$route.params.id))
		this.getContactFields(parseInt(this.$route.params.id))
		this.setTabs()
		this.ready = true
		this.$q.loading.hide()
	},
}
</script>

<style lang="scss" scoped>
.router-view-wrapper {
	padding: 1.5rem 3rem;
	background-color: #fff;
	@media (max-width: 768px) {
		padding: 1rem 0.5rem;
	}
}
.tabs-wrapper {
	position: sticky;
	background: #fff;
	border: 1px solid $grey-3;
	z-index: 5;
}
</style>

<style lang="scss">
.settings-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	padding: 0 1rem 0 1rem;
	margin-bottom: 1rem;
	width: 100%;
	background: $grey-2;
	border: 1px solid $grey-4;
}
.setting-title {
	font-size: 1.5rem;
	font-weight: 400;
	line-height: 2rem;
	letter-spacing: normal;
	margin-top: 16px;
	margin-bottom: 16px;
	padding-bottom: 16px;
	text-align: left;
	border-bottom: 1px solid #e0e0e0;
	cursor: default;
}

.field-fab-wrapper {
	position: sticky;
	bottom: 20px;
	z-index: 5;
	width: fit-content;
	margin: 0 auto;
}
</style>
